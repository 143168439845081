import React from 'react';
import { Box, Button, Heading, Text, Stack, Link } from "@chakra-ui/react";
import BackgroundImage from '../../images/backgrounds/home-BR.svg';
import { Link as GatsbyLink } from 'gatsby';
import { useBreakpointValue } from "@chakra-ui/react"

export default function IntroductionSection() {
  const size = useBreakpointValue(["2xl", "4xl"])
  return <Box backgroundImage={`url(${BackgroundImage})`} backgroundPosition="bottom right" backgroundSize={["contain", "contain", '80%']} backgroundRepeat="no-repeat" py={150} minHeight="100vh">
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box w="100%">
        <Stack spacing={8}>
          <Heading as="h1" size={size} data-sal="slide-right" data-sal-duration="1000">Ignite the power of individual action.</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Makeripples is an online platform built to help you create real-world action.</Text>
          <Link to="/about" as={GatsbyLink} data-sal="slide-up" data-sal-duration="1000" data-sal-delay="1000" >
            <Button colorScheme="brand">
              Learn more
            </Button>
          </Link>
        </Stack>
      </Box>
    </Box>
  </Box>
}