import React from 'react';
import { Box, Button, Heading, Text, Stack, Link } from "@chakra-ui/react";
import BackgroundImage from '../../images/backgrounds/orgs-MR.png';
import { Link as GatsbyLink } from 'gatsby';

export default function RegisterInterestSection() {
  return <Box bgGradient="linear(to-b, #5B1258, #2F082E)">
    <Box py={150}>
      <Stack direction="row">
        <Box w="100%" p={["2rem", "2rem", "4rem"]}>
          <Stack spacing={8}>
            <Heading size="2xl" color="white" data-sal="slide-right" data-sal-duration="1000">Are you a change-loving individual?</Heading>
            <Text color="white" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Let us know if you are interested in an online space to get amongst your community, find organisations that share your vision and take action.</Text>
            <Link href="https://forms.gle/pVeWjmGHq1it16Vt9" as={GatsbyLink} data-sal="slide-up" data-sal-duration="1000" data-sal-delay="1000" >
              <Button colorScheme="brand">
                Register your interest
              </Button>
            </Link>
          </Stack>
        </Box>
        <Box w="100%" display={['none', null, null, 'block']}>
          <Box 
            maxW="600px"
            w="100%" 
            h="100%"
            marginLeft="auto"
            boxShadow={['unset', 'unset', 'unset', 'unset', "inset 10px 0px 9px -9px rgba(0,0,0,0.75)"]}
            backgroundImage={`url(${BackgroundImage})`} 
            backgroundSize="auto 90%" 
            backgroundPosition={['left','left','left','left', 'right']} 
            backgroundRepeat="no-repeat" />
          </Box>
      </Stack>
    </Box>
  </Box>
}