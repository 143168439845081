import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import IntroductionSection from "../containers/sections/introduction-section";
import MakeRipplesSection from "../containers/sections/make-ripples-section";
import OtherPagesSection from "../containers/sections/other-pages-section";
import RegisterInterestSection from "../containers/sections/register-interest-section";
import SummarySection from "../containers/sections/summary-section";

const Index = ({ location }) => {
  return <Layout activePage={location.pathname}>
    <Helmet>
      <title>makeripples - Home</title>
    </Helmet>
    <IntroductionSection />
    <SummarySection />
    <RegisterInterestSection />
    <OtherPagesSection activePage={location.pathname} />
    <MakeRipplesSection />
  </Layout>
}

export default Index
