import React from 'react';
import { Box, Button, Heading, Text, Stack, Link } from "@chakra-ui/react";
import BackgroundImage from '../../images/backgrounds/home-mr.png';
import { Link as GatsbyLink } from 'gatsby';

export default function SummarySection() {
  return <Box backgroundColor="white" backgroundImage={['none', 'none', 'none', `url(${BackgroundImage})`]} backgroundPosition="center right" backgroundSize="50%" backgroundRepeat="no-repeat" py={150}>
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box w={['100%', '100%', '100%', '50%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">
            Are you a for-purpose organisation looking for an online tool to help elevate your communication and inspire your community to take action?
          </Heading>
          <Heading data-sal="slide-right" data-sal-duration="1000" data-sal-delay="200">
            Our platform allows you to:
          </Heading>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="400">
            <Text>
              Support your mahi by building engaging online educational and training programmes, targeted at the right members of your community.
            </Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="600">
            <Text>
              Follow-up a course, event or workshop with a realistic and actionable next-steps programme.
            </Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="800">
            <Text>
              Follow-up an ethical product purchase with a programme to help encourage behaviour change to inspire good habits.
            </Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="800">
            <Text>
              Collect inspiring stories.
            </Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="800">
            <Text>
              Understand the real-world impact made by each action, no matter how small.
            </Text>
          </Box>
          <Link to="/product" as={GatsbyLink} data-sal="slide-up" data-sal-duration="1000" data-sal-delay="1000">
            <Button colorScheme="brand">
              More about the platform
            </Button>
          </Link>
        </Stack>
      </Box>
    </Box>
  </Box>
}